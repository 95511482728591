import { Routes, Route } from 'react-router-dom';

import { Footer } from './Footer/Footer';
import { Main } from './Main/Main';
import { Terms } from './Terms/Terms';
import { PersonalInformation } from './PersonalInformation/PersonalInformation';
import { NotFound } from '../404';
import { CookieRules } from './CookieRules/CookieRules';
import { Helmet } from 'react-helmet-async';

export const WisdomFromTheAshes = () => {
  return (
    <>
      <Helmet>
        <title>Мъдрости от пепелта</title>
        <meta name="description" content="&quot;Мъдрости от пепелта&quot; - Красимир Костадинов. Сборник с кратки поучителни сентенции за живота." />
        <link rel="canonical" href="/madrosti-ot-pepelta"/>
      </Helmet>
      <Routes>
        <Route path="/" element={<Main />}/>
        <Route path="terms" element={<Terms />} />
        <Route path="personal-info-rules" element={<PersonalInformation />} />
        <Route path="cookies-policy" element={<CookieRules />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  )
}
